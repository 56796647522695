var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('region',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
        _vm.dialog = _;
      },"added":function (_) {
        _vm.dialog = _;
        _vm.getRegions();
      }}}),_c('v-card-title',[_c('h1',[_vm._v(_vm._s(_vm.$t("regions")))]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('add-a-new-region'))+" ")],1)],1),_c('div',{staticClass:"d-flex flex-column my-2"},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("province"))+": ")]),_c('v-select',{attrs:{"loading":_vm.loading,"items":_vm.provinecs,"solo":"","filled":"","item-text":"name","item-value":"id"},model:{value:(_vm.options.ProvinceId),callback:function ($$v) {_vm.$set(_vm.options, "ProvinceId", $$v)},expression:"options.ProvinceId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t("search")))]),_c('v-text-field',{attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","filled":"","loading":_vm.loading,"clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1)],1),_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.regions,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.editRegion(item)}}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")],1)]}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")]}},{key:"item.index",fn:function(ref){
      var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }