var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('store',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
        _vm.dialog = _;
      },"added":function (_) {
        _vm.dialog = _;
        _vm.getStores();
      }}}),_c('h1',[_vm._v(_vm._s(_vm.$t('repositories')))]),_c('div',{staticClass:"d-flex flex-column my-2"},[_c('v-row',{staticClass:"d-flex align-baseline justify-space-between"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('region'))+" ")]),_c('v-select',{attrs:{"label":_vm.$t('region'),"loading":_vm.loading,"items":_vm.regions,"solo":"","item-text":"name","item-value":"id"},model:{value:(_vm.options.RegionId),callback:function ($$v) {_vm.$set(_vm.options, "RegionId", $$v)},expression:"options.RegionId"}})],1),_c('v-btn',{attrs:{"x-large":"","color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('add-a-new-repository'))+" ")],1)],1),_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.stores,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},on:{"click:row":function (_) {
          _vm.$router.push({
            name: 'store-detials',
            params: {
              id: _.id,
            },
          });
        }},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.editRegion(item)}}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")],1)]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(item.status != null)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.status(item.status))+" ")],1)]}}],null,true)},[_c('v-list',[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStatus(item)}},model:{value:(_vm.stores[index].status),callback:function ($$v) {_vm.$set(_vm.stores[index], "status", $$v)},expression:"stores[index].status"}},[_c('v-list-item',[_c('v-radio',{attrs:{"label":"Normal","value":{
                    id: item.status.id,
                    isNormal: true,
                    isBlocked: false,
                    isIndeptedness: false,
                    isExceptions: false,
                    companyId: _vm.options.CompanyId,
                    pharmacyId: item.id,
                  }}})],1),_c('v-list-item',[_c('v-radio',{attrs:{"label":"Blocked","value":{
                    id: item.status.id,
                    isNormal: false,
                    isBlocked: true,
                    isIndeptedness: false,
                    isExceptions: false,
                    companyId: _vm.options.CompanyId,
                    pharmacyId: item.id,
                  }}})],1),_c('v-list-item',[_c('v-radio',{attrs:{"label":"Indeptedness","value":{
                    id: item.status.id,
                    isNormal: false,
                    isBlocked: false,
                    isIndeptedness: true,
                    isExceptions: false,
                    companyId: _vm.options.CompanyId,
                    pharmacyId: item.id,
                  }}})],1),_c('v-list-item',[_c('v-radio',{attrs:{"label":"Exceptions","value":{
                    id: item.status.id,
                    isNormal: false,
                    isBlocked: false,
                    isIndeptedness: false,
                    isExceptions: true,
                    companyId: _vm.options.CompanyId,
                    pharmacyId: item.id,
                  }}})],1)],1)],1)],1):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }