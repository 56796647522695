<template>
  <div>
    <Add
      :dialog="add"
      @updated="
        (_) => {
          add = _;
        }
      "
      @added="
        (_) => {
          add = _;
          getDoctors();
        }
      "
    />
    <div class="d-flex align-content-center justify-space-between">
      <h1 class="mb-3">{{ $t('doctors') }}</h1>
      <v-btn @click="add = true" x-large color="secondary">
        <v-icon>mdi-plus</v-icon>
        <span>{{ $t("add-doctor") }}</span>
      </v-btn>
    </div>
    <v-card :loading="loading" class="transparent">
      <v-row>
        <v-col cols="3">
          <label for="comp"> {{ $t("doctor-class") }}</label>
          <v-autocomplete
            class="mt-2"
            @change="getDoctors()"
            clearable
            v-model="options.SpecId"
            :items="specs"
            :loading="loading"
            solo
            filled
            item-value="id"
            item-text="name"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="3" style="padding-bottom: 0">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <!-- v-chip-group classes -->
        <v-col cols="12" style="padding-top: 0">
          <v-chip-group
            v-model="classId"
            @change="getDoctors()"
            :loading="loading"
            active-class="success"
          >
            <v-chip
              large
              v-for="(item, index) in classes"
              :key="index"
              filter
              label
              :value="item.id"
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12">
          <v-data-table
            disable-pagination
            :headers="headers"
            :items="doctors"
            :loading="loading"
            :no-data-text="$t('no-data')"
            :hide-default-footer="true"
            @click:row="
              (item) => {
                $router.push({
                  name: 'doctor-detials',
                  params: { id: item.id },
                });
              }
            "
          >
            <template v-slot:item.created="{ item }">
              <span dir="ltr">
                {{ $service.formatDate(new Date(item.created)) }}
              </span>
            </template>
            <template v-slot:item.isBlocked="{ item }">
              <v-chip :color="statusColor(item)">
                {{ item.status == null ? "None" : item.status }}
              </v-chip>
            </template>

            <template v-slot:item.edit="{ item }">
              <div class="d-flex">
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  :color="item.isBlocked ? 'success' : 'error'"
                  @click.stop="toggleBlock(item.id)"
                >
                  <v-icon class="mr-1">
                    mdi-{{ item.isBlocked ? "check" : "alert-circle" }}
                  </v-icon>
                  {{ item.isBlocked ? $t('unblock') : $t('block') }}
                </v-btn>
                <v-btn text @click.stop="editRegion(item)" color="primary">
                  <v-icon class="ml-1">mdi-pencil</v-icon>
                  {{ $t('edit') }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import Add from "./Add.vue";
export default {
  components: { Add },
  data() {
    return {
      add: false,
      orderDialog: false,
      company: {},
      options: {
        Search: "",
        ClassId: "",
        SpecId: "",
        PageIndex: 1,
        PageSize: 10,
        OfficeId: this.$store.state.userInfo.office.id,
        CompanyId: this.$store.state.company.id,
      },
      doctors: [],
      specs: [],
      total: 0,
      classId: 0,
      companies: [],
      loading: true,
      dialog: false,
      classes: [],
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: this.$t('specialty'), value: "specName" },
        { text: this.$t('Debts'), value: "debts" },
        { text: this.$t('doctor-class'), value: "className" },
        { text: this.$t("region"), value: "regionName" },
        // { text: this.$t('note'), value: "descrption" },
        { text: this.$t('status') , value: "isBlocked" },
        { text: this.$t("actions"), value: "edit" },
      ],
    };
  },
  created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.company = this.companies[0];
        this.options.CompanyId = this.$store.state.company.id;
        this.companies.unshift({ id: "", name: "All" });
        this.$http.get("/Doctors/classes").then((st) => {
          this.classes = st.data.result;
          this.classes.unshift({ key: 0, name: "All" });
          this.classId = 0;

          this.$http.get("/doctors/Specialities").then((st) => {
            this.loading = false;
            this.specs = st.data.result;
            this.specs.unshift({ id: "", name: "All" });
          });
          this.getDoctors();
        });
      });
  },
  computed: {},
  methods: {
    statusColor(item) {
      let status = item.status == null ? "none" : item.status.toLowerCase();
      let colors = {
        loyal: "success",
        sharing: "info",
        trailer: "orange",
        stop: "error",
        reject: "error",
        none: "grey",
      };
      return colors[status];
    },
    getDoctors() {
      this.loading = true;

      if (this.classId !== 0) {
        this.options.ClassId = this.classId;
      } else {
        this.options.ClassId = "";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http
        .get("/Doctors" + filter.replace("?&", "?"))
        .then((prod) => {
          this.total = prod.data.count;
          this.doctors = prod.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    toggleBlock(id) {
      this.loading = true;
      this.$http
        .delete(`/Doctors/blockUnBlock/${id}`)
        .then(() => {
          this.loading = false;
          this.getDoctors();
        })
        .catch(() => {
          this.getDoctors();
        });
    },
    editRegion(item) {
      this.$store.commit("regions/updateRegion", item);
      this.$store.commit("regions/toggleEdit", true);
      this.add = true;
    },
  },
  watch: {
    options: {
      handler() {
        this.getDoctors();
      },
      deep: true,
    },
  },
};
</script>
