<template xmlns:regoin="http://www.w3.org/1999/html">
  <div>
    <region
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @added="
        (_) => {
          dialog = _;
          getRegions();
        }
      "
      :dialog="dialog"
    />
    <v-card-title>
      <h1>{{ $t("regions") }}</h1>
      <v-spacer>

      </v-spacer>
        <v-btn @click="dialog = !dialog" large color="secondary">
          <v-icon>mdi-plus-circle</v-icon>
          {{ $t('add-a-new-region') }}
        </v-btn>
    </v-card-title>
    
    <div class="d-flex flex-column my-2">
      <v-row class="">
        <v-col cols="4">
          <label for=""> {{ $t("province") }}: </label>
          <v-select
            :loading="loading"
            :items="provinecs"
            solo
            filled
            item-text="name"
            item-value="id"
            v-model="options.ProvinceId"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        disable-pagination
        :loading="loading"
        :headers="headers"
        :items="regions"
        :no-data-text="$t('no-data')"
        :hide-default-footer="true"
      >
        <template #item.edit="{ item }">
          <v-btn @click="editRegion(item)" color="primary" text>
            <v-icon class="ml-1">mdi-pencil</v-icon>
            {{ $t('edit') }}
          </v-btn>
        </template>
        <template #item.created="{ item }">
          {{ $service.formatDate(new Date(item.created)) }}
        </template>
        <template #item.index="{  index}">
          {{ index + 1 }}
        </template>
      </v-data-table>
      <div class="d-flex justify-center">
        <v-pagination
          v-model="options.PageIndex"
          :length="parseInt(total / options.PageSize) + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Region from "./Region.vue";
export default {
  components: {
    Region,
  },
  name: "Regoins",
  watch: {
    options: {
      handler: function() {
        this.getRegions();
      },
      deep: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      provinecs: [],
      options: {
        ProvinceId: "",
        PageIndex: 1,
        PageSize: 10,
      },
      regions: [],
      total: 0,
      headers: [
        {
          name: "#",
          value: "index",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: this.$t("province"), value: "provinecName" },
        { text: this.$t("created"), value: "created" },
        { text: "", value: "edit", sortable: false },
      ],
    };
  },
  methods: {
    editRegion(item) {
      this.$store.commit("regions/updateRegion", item);
      this.$store.commit("regions/toggleEdit", true);
      this.dialog = true;
    },
    getRegions() {
      this.loading = true;
      this.regions = [];
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http("/Regions" + filter.replace("?&", "?")).then((res) => {
        this.regions = res.data.result.data;
        this.loading = false;
        this.total = res.data.result.count;
      });
    },
  },
  created() {
    this.$http("/Provinecs").then((res) => {
      this.provinecs = res.data.result;
      this.provinecs.unshift({ id: "", name: "All" });
      this.getRegions();
    });
  },
};
</script>

<style scoped></style>
