<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card outlined :loading="loading">
      <v-card-title class="text-h5 my-3">
        {{ isEdit ? $t('edit-pharmacy') : $t('add-pharmacy') }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form ref="addPharmacy">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                hide-detials="auto"
                v-model="inputs.fullName"
                :label="$t('name')"
                outlined
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="inputs.regionId"
                :label="$t('region')"
                outlined
                :items="regions"
                item-text="name"
                :loading="loading"
                item-value="id"
                :search-input.sync="search"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                :label="$t('descrption')"
                name="descrption"
                v-model="inputs.descrption"
                outlined
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                :label="$t('closest-point')"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
                v-model="inputs.closestPoint"
                name="closestPoint"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-detials="auto"
                outlined
                hide-details="auto"
                :label="$t('Debts')"
                v-model="inputs.debts"
                placeholder="0"
                :pattern="/^-?\d+\.?\d*$/"
                id="id"
                suffix="IQD"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-file-input
                v-model="file"
                :label="$t('license-picture-0')"
                outlined
                hide-details
                accept="image/*"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addPharmacy">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.regions.isEdit;
    },
    item: {
      get() {
        return this.$store.state.regions.region;
      },
    },
  },
  data() {
    return {
      loading: true,
      regions: [],
      search: "",
      file: null,
      inputs: {
        fullName: "",
        descrption: "",
        licenseUrl: "",
        longtude: "",
        latitude: "",
        regionId: "",
        closestPoint: "",
        debts: 0,
        officeId: "",
      },
    };
  },
  created() {
    this.$http.get("/regions").then((com) => {
      this.regions = com.data.result.data;
      this.loading = false;
      this.checkIsEdit();
    });
  },
  mounted() {
    this.$http.get("/regions").then((com) => {
      this.regions = com.data.result.data;
      this.loading = false;
      this.checkIsEdit();
    });
  },
  props: ["dialog"],
  methods: {
    addPharmacy() {
      var val = this.$refs.addPharmacy.validate();
      if (val) {
        this.inputs.officeId = this.$store.state.userInfo.office.id;
        this.loading = true;
        // this.inputs.debts =
        //   typeof this.inputs.debts == "string"
        //     ? parseInt(this.inputs.debts.replace(",", ""))
        //     : this.inputs.debts;
        this.$store.dispatch("uploadFile", this.file).then((url) => {
          if (typeof url === "string") {
            this.inputs.licenseUrl = url;
          }
          if (typeof this.inputs.licenseUrl === "object") {
            this.inputs.licenseUrl = "";
          }
          if (this.isEdit) {
            this.$http
              .put("/Pharmacies/put/" + this.item.id, this.inputs)
              .then(() => {
                this.loading = false;

                this.$emit("added", !this.dialog);
                this.dialog = false;
              });
          } else {
            this.$http.post("/Pharmacies/post", this.inputs).then(() => {
              this.loading = false;

              this.$emit("added", !this.dialog);
              this.dialog = false;
            });
          }
        });
      }
    },
    checkIsEdit() {
      if (this.isEdit) {
        this.loading = true;
        this.$http.get("/Pharmacies/" + this.item.id).then((res) => {
          Object.keys(this.inputs).forEach((key) => {
            this.inputs[key] = res.data.result[key];
          });
          this.regions.push(res.data.result.region);
          if (this.item.licenseUrl == null) {
            this.item.licenseUrl = "";
          }
          let fileName = this.item.licenseUrl.split("_");
          fileName = fileName[fileName.length - 1];
          this.file = new File(["1"], fileName);
          this.inputs.regionId = res.data.result.region.id;
          this.loading = false;
        });
        // this.inputs = this.item;

        // this.inputs.regionId = this.regions.find((item) => {
        //   return item.name == this.item.regionName;
        // })[0];
        //
      } else {
        this.inputs = {
          fullName: "",
          descrption: "",
          licenseUrl: "",
          longtude: "",
          latitude: "",
          regionId: "",
          closestPoint: "",
          officeId: "",
        };
        // this.$refs.addPharmacy.reset()
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog != true) {
        this.$store.commit("regions/updateStores", {});
        this.$store.commit("regions/toggleEdit", false);
        this.$emit("updated", this.dialog);
        this.$refs.addPharmacy.reset();
      } else {
        this.checkIsEdit();
      }
    },
    search(val) {
      if (val != null && val != "") {
        this.loading = true;
        this.$http.get("/regions?Search=" + val).then((res) => {
          this.regions = res.data.result.data;
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
