<template xmlns:regoin="http://www.w3.org/1999/html">
  <div>
    <store
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @added="
        (_) => {
          dialog = _;
          getStores();
        }
      "
      :dialog="dialog"
    />
    <h1>{{ $t('repositories') }}</h1>
    <div class="d-flex flex-column my-2">
      <v-row class="d-flex align-baseline justify-space-between">
        <v-col cols="4">
          <label for=""> {{ $t('region') }} </label>
          <v-select
            :label="$t('region')"
            :loading="loading"
            :items="regions"
            solo
            item-text="name"
            item-value="id"
            v-model="options.RegionId"
          ></v-select>
        </v-col>
        <v-btn @click="dialog = !dialog" x-large color="secondary">
          <v-icon class="mr-2">mdi-plus-circle</v-icon>
          {{ $t('add-a-new-repository') }}
        </v-btn>
      </v-row>
      <v-data-table
        disable-pagination
        :loading="loading"
        :headers="headers"
        :items="stores"
        :no-data-text="$t('no-data')"
        :hide-default-footer="true"
        @click:row="
          (_) => {
            $router.push({
              name: 'store-detials',
              params: {
                id: _.id,
              },
            });
          }
        "
      >
        <template #item.edit="{ item }">
          <v-btn @click.stop="editRegion(item)" color="primary" text>
            <v-icon class="ml-1">mdi-pencil</v-icon>
            {{ $t('edit') }}
          </v-btn>
        </template>
        <template #item.created="{ item }">
          {{ $service.formatDate(new Date(item.created)) }}
        </template>
        <template v-slot:item.status="{ item, index }">
          <!-- <v-chip color="info">
            {{ status(item.status) }}
          </v-chip> -->
          <v-menu v-if="item.status != null" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="info" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-pencil</v-icon>
                {{ status(item.status) }}
              </v-btn>
            </template>
            <v-list>
              <v-radio-group
                v-model="stores[index].status"
                @change="updateStatus(item)"
              >
                <v-list-item>
                  <v-radio
                    label="Normal"
                    :value="{
                      id: item.status.id,
                      isNormal: true,
                      isBlocked: false,
                      isIndeptedness: false,
                      isExceptions: false,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
                <v-list-item>
                  <v-radio
                    label="Blocked"
                    :value="{
                      id: item.status.id,
                      isNormal: false,
                      isBlocked: true,
                      isIndeptedness: false,
                      isExceptions: false,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
                <v-list-item>
                  <v-radio
                    label="Indeptedness"
                    :value="{
                      id: item.status.id,
                      isNormal: false,
                      isBlocked: false,
                      isIndeptedness: true,
                      isExceptions: false,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
                <v-list-item>
                  <v-radio
                    label="Exceptions"
                    :value="{
                      id: item.status.id,
                      isNormal: false,
                      isBlocked: false,
                      isIndeptedness: false,
                      isExceptions: true,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <div class="d-flex justify-center">
        <v-pagination
          v-model="options.PageIndex"
          :length="parseInt(total / options.PageSize) + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Store from "./Store.vue";

export default {
  components: {
    Store,
  },
  name: "Regoins",
  watch: {
    options: {
      handler: function() {
        this.getStores();
      },
      deep: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      stores: [],
      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 10,
        OfficeId: this.$store.state.userInfo.office.id,
        CompanyId: this.$store.state.userInfo.userData.companyId,
      },
      regions: [],
      total: 0,
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: this.$t('region'), value: "regionName" },
        { text: this.$t('orders'), value: "ordersCount" },
        { text: this.$t('status'), value: "status" },
        { text: this.$t('Debts'), value: "debts" },
        { text: this.$t("actions"), value: "edit" },
      ],
    };
  },
  methods: {
    status(stat) {
      var ret = "Select";
      Object.keys(stat).forEach((key) => {
        if (stat[key] == true) {
          //
          ret = key;
        }
      });
      return ret.replace("is", "");
    },
    editRegion(item) {
      this.$store.commit("regions/updateRegion", item);
      this.$store.commit("regions/toggleEdit", true);
      this.dialog = true;
    },
    getStores() {
      this.loading = true;
      this.stores = [];

      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http("/Repositories" + filter.replace("?&", "?")).then((res) => {
        this.stores = res.data.data;
        this.loading = false;
        this.total = res.data.count;
      });
    },
    updateStatus(repository) {
      repository.status.repositoryId = repository.id;
      repository.status.companyId = this.$store.state.company.id;
      this.$http
        .put(
          "/Repositories/connectRepositorywithCompany/put/" +
            repository.status.id,
          repository.status
        )
        .then(() => {
          this.getStores();
        });
    },
  },
  created() {
    this.$http("/regions").then((res) => {
      this.regions = res.data.result.data;
      this.regions.unshift({ id: "", name: "All" });
      this.getStores();
    });
  },
};
</script>

<style scoped></style>
