<template xmlns:regoin="http://www.w3.org/1999/html">
  <div>
    <add
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @added="
        (_) => {
          dialog = _;
          getPharmacies();
        }
      "
      :dialog="dialog"
    />
    <depts
      @updated="
        (_) => {
          deptsDialog = _;
        }
      "
      @added="
        (_) => {
          deptsDialog = _;
          getPharmacies();
        }
      "
      :dialog="deptsDialog"
    />
    <div class="d-flex justify-space-between">
      <h1>{{ $t('pharmacies') }}</h1>
      <v-btn @click="dialog = !dialog" x-large color="secondary">
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        {{ $t('add-pharmacy') }}
      </v-btn>
    </div>
    <div class="d-flex flex-column my-2">
      <v-row class="">
        <!-- <v-col cols="4">
          <label for=""> {{ $t('region') }} </label>
          <v-autocomplete
            :label="$t('region')"
            :loading="loading"
            :items="regions"
            solo
            item-text="name"
            item-value="id"
            v-model="options.regionId"
          ></v-autocomplete>
        </v-col> -->
        <v-col cols="12" md="3">
          <h3>Region</h3>
          <v-autocomplete @input="getPharmacies()" solo @click:clear="()=> {options.regionId = '', getPharmacies()}" v-model="options.regionId" v-debounce:500ms="getRegions" :search-input.sync="searchRegion" clearable item-text="name" item-value="id"  :items="regions" class="mr-1"  :loading="loading" no-data-text="لا توجد بيانات" hide-details=""  />
      </v-col>
        <v-col cols="3">
           <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        disable-pagination
        :loading="loading"
        :headers="headers"
        :items="pharmacies"
        :no-data-text="$t('no-data')"
        :hide-default-footer="true"
        @click:row="
          (_) => {
            $router.push({
              name: 'pharmacy-detials',
              params: {
                id: _.id,
              },
            });
          }
        "
      >
        <template #item.edit="{ item }">
          <div class="d-flex">
            <v-btn @click.stop="payDepts(item)" class="mx-1" color="primary">
              <v-icon class="ml-1">mdi-cash</v-icon>
              {{ $t('pay-off-depts') }}
            </v-btn>
            <v-btn text @click.stop="editRegion(item)" color="primary">
              <v-icon class="ml-1">mdi-pencil</v-icon>
              {{ $t('edit') }}
            </v-btn>
          </div>
        </template>
        <template #item.created="{ item }">
          {{ $service.formatDate(new Date(item.created)) }}
        </template>
        <template v-slot:item.status="{ item, index }">
          <!-- <v-chip color="info">
            {{ status(item.status) }}
          </v-chip> -->
          <v-menu v-if="item.status != null" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="info" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-pencil</v-icon>
                {{ status(item.status) }}
              </v-btn>
            </template>
            <v-list>
              <v-radio-group
                v-model="pharmacies[index].status"
                @change="updateStatus(item)"
              >
                <v-list-item>
                  <v-radio
                    label="Normal"
                    :value="{
                      id: item.status.id,
                      isNormal: true,
                      isBlocked: false,
                      isIndeptedness: false,
                      isExceptions: false,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
                <v-list-item>
                  <v-radio
                    label="Blocked"
                    :value="{
                      id: item.status.id,
                      isNormal: false,
                      isBlocked: true,
                      isIndeptedness: false,
                      isExceptions: false,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
                <v-list-item>
                  <v-radio
                    label="Indeptedness"
                    :value="{
                      id: item.status.id,
                      isNormal: false,
                      isBlocked: false,
                      isIndeptedness: true,
                      isExceptions: false,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
                <v-list-item>
                  <v-radio
                    label="Exceptions"
                    :value="{
                      id: item.status.id,
                      isNormal: false,
                      isBlocked: false,
                      isIndeptedness: false,
                      isExceptions: true,
                      companyId: options.CompanyId,
                      pharmacyId: item.id,
                    }"
                  ></v-radio>
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <div class="d-flex justify-center">
        <v-pagination
          v-model="options.PageIndex"
          :length="parseInt(total / options.PageSize) + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Add from "./Add.vue";
import Depts from "./Depts.vue";
export default {
  components: {
    Add,
    Depts,
  },
  name: "Regoins",
  watch: {
    options: {
      handler: function () {
        this.getPharmacies();
      },
      deep: true,
    },
  },
  data() {
    return {
      dialog: false,
      deptsDialog: false,
      loading: true,
      pharmacies: [],
      searchRegion: "",
      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 50,
        OfficeId: this.$store.state.userInfo.office.id,
        CompanyId: this.$store.state.company.id,
        regionId: ""
      },
      filter:{
        regionId: ''
      },
      regions: [],
      total: 0,
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: this.$t('region'), value: "regionName" },
        // { text: this.$t('orders'), value: "ordersCount" },
        { text: this.$t('status'), value: "status" },
        { text: this.$t('Debts'), value: "debts" },
        { text: this.$t('orders'), value: "ordersCount" },
        { text: this.$t('net-amounts'), value: "netAmounts" },
        { text: this.$t('actions'), value: "edit", sortable: false },
      ],
    };
  },
  methods: {


    async getRegions() {
            this.loading = true
            try {
                let res = await this.$http.get(`Regions?Search=${this.searchRegion}`)
                this.regions = res.data.result.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
    payDepts(pharmacy) {
      this.$store.commit("regions/updateRegion", pharmacy);

      this.deptsDialog = true;
    },
    status(stat) {
      var ret = "Select";
      Object.keys(stat).forEach((key) => {
        if (stat[key] == true) {
          //
          ret = key;
        }
      });
      return ret.replace("is", "");
    },
    editRegion(item) {
      this.$store.commit("regions/updateRegion", item);
      this.$store.commit("regions/toggleEdit", true);
      this.dialog = true;
    },
    getPharmacies() {
      this.loading = true;
      this.pharmacies = [];

      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http("/Pharmacies" + filter.replace("?&", "?")).then((res) => {
        this.pharmacies = res.data.data;
        this.loading = false;
        this.total = res.data.count;
      });
    },
    updateStatus(pharmacy) {
      pharmacy.status.pharmacyId = pharmacy.id;
      this.$http
        .put(
          "/Pharmacies/connectPharmacywithCompany/put/" + pharmacy.status.id,
          pharmacy.status
        )
        .then(() => {
          this.getPharmacies();
        });
    },
  },
  created() {
    this.$http.get("/regions?pageSize=1000&pageIndex=1").then((res) => {
      this.regions = res.data.result.data;
      this.regions.unshift({ id: "", name: "All" });
      this.getPharmacies();
    });
  },
};
</script>
  
<style scoped></style>
