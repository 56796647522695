<template>
  <div>
    <!-- use drawer -->
    <drawer :title="$t('selling-points')" :items="drawer.items" icon="selling">
    </drawer>
    <!-- use doctors -->
    <doctors v-if="tab == 'doctors'" />
    <!-- use Pharmacies -->
    <pharmacies v-if="tab == 'pharmacies'" />
    <!-- use regions -->
    <regions v-if="tab == 'regoins'" />
    <!-- Stores -->
    <stores v-if="tab == 'stores'" />
  </div>
</template>
<script>
// import drawer comonent
import Drawer from "@/layouts/Drawer";
// import doctors component
import Doctors from "./doctors/Doctors.vue";
// import Pharmacies component
import Pharmacies from "./pharmacies/Pharmacies.vue";
// import regions component
import Regions from "./regoins/Regoins.vue";
import Stores from "./stores/Stores.vue";
export default {
  components: {
    Drawer,
    Doctors,
    Pharmacies,
    Regions,
    Stores,
  },
  data() {
    return {
      drawer: {
        items: [
          {
            title: this.$t('doctors'),
            icon: "mdi-medical-bag",
            route: "selling-doctors",
          },
          {
            title: this.$t('pharmacies'),
            icon: "mdi-pill",
            route: "selling-pharmacies",
          },
          {
            title: this.$t('repositories'),
            icon: "mdi-office-building-marker",
            route: "selling-stores",
          },
          {
            title: this.$t('regoins'),
            icon: "mdi-map-search",
            route: "selling-regoins",
          },
        ],
      },
    };
  },
  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  created() {
    if (this.tab == undefined || this.tab == "") {
      this.$router.push({
        name: this.drawer.items.filter(
          (v) => v.access == true || v.access == undefined
        )[0].route,
      });
    }
  },
  beforeMount() {
    this.$store.commit("TOGGLE_DRAWER", true);
  },
};
</script>