<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="text-h5 my-3">
        {{ isEdit ? $t('edit') : $t('add') }} {{ $t('Doctor') }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>

      <v-form ref="addDoctor" :loading="loading" @submit.prevent="submit">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                hide-detials="auto"
                outlined
                v-model="inputs.fullName"
                :label="$t('name')"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                v-model="inputs.regionId"
                :label="$t('region')"
                hide-details="auto"
                outlined
                :items="regions"
                item-text="name"
                item-value="id"
                :search-input.sync="searchRegion"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="inputs.specId"
                :label="$t('specialty')"
                hide-details="auto"
                outlined
                :items="specs"
                item-text="name"
                :disabled="loading"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="inputs.classId"
                :label="$t('doctor-class')"
                hide-details="auto"
                outlined
                :items="classes"
                item-text="name"
                :disabled="loading"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                hide-detials="auto"
                outlined
                v-model="inputs.closestPoint"
                :label="$t('closest-point')"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                hide-detials="auto"
                outlined
                hide-details="auto"
                :label="$t('Debts')"
                v-model="inputs.debts"
                @input="inputs.debts = $service.formatCurrency(inputs.debts)"
                placeholder="0"
                :pattern="/^-?\d+\.?\d*$/"
                suffix="IQD"
                id="id"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                hide-detials="auto"
                outlined
                v-model="inputs.descrption"
                :label="$t('descrption')"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-file-input
                v-model="file"
                :label="$t('image')"
                outlined
                hide-details
                accept="image/*"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <div class="d-flex align-center justify-end py-3 px-3">
          <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
            <f-icon icon="times-circle" />
            {{ $t('cancel') }}</v-btn
          >
          <v-btn color="primary" :loading="loading" @click="submit()">
            <v-icon>mdi-plus</v-icon>
            {{ $t('save') }}</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog"],
  computed: {
    isEdit() {
      return this.$store.state.regions.isEdit;
    },
    item: {
      get() {
        return this.$store.state.regions.region;
      },
    },
  },
  data() {
    return {
      loading: false,
      file: null,
      inputs: {
        fullName: "",
        descrption: "",
        specId: "",
        classId: "",
        imageUrl: "",
        longtude: "",
        latitude: "",
        regionId: "",
        debts: 0,
        closestPoint: "",
      },
      regions: [],
      searchRegion: "",
      classes: [],
      specs: [],
    };
  },
  created() {
    this.loading = true;
    this.$http.get("/Doctors/Classes").then((res) => {
      this.classes = res.data.result;
      this.$http.get("/Doctors/Specialities").then((res) => {
        this.specs = res.data.result;
        this.getRegions();
      });
    });
  },
  methods: {
    getRegions(search = "") {
      this.loading = true;
      let params = {};
      if (search) {
        params.search = search;
      }
      this.$http.get(`/regions`, { params }).then((res) => {
        this.regions = res.data.result.data;
        this.loading = false;
      });
    },
    submit() {
      this.loading = true;
      this.inputs.officeId = this.$store.state.userInfo.office.id;
      this.$store.dispatch("uploadFile", this.file).then((url) => {
        if (typeof url === "string") {
          this.inputs.imageUrl = url;
        }
        if (typeof this.inputs.imageUrl === "object") {
          this.inputs.imageUrl = "";
        }
        this.inputs.debts =
           this.inputs.debts.toString().replace(/,/g, "");
        if (this.isEdit) {
          this.$http
            .put("/Doctors/put/" + this.item.id, this.inputs)
            .then(() => {
              this.loading = false;
              this.dialog = false;
              this.$emit("added", this.dialog);
            });
        } else {
          this.$http.post("/Doctors/post", this.inputs).then(() => {
            this.loading = false;
            this.dialog = false;
            this.$emit("added", this.dialog);
          });
        }
      });
    },
    checkIsEdit() {
      if (this.isEdit) {
        this.loading = true;
        this.$http.get("/Doctors/" + this.item.id).then((res) => {
          Object.keys(this.inputs).forEach((key) => {
            this.inputs[key] = res.data.result[key];
          });
          this.regions.push(res.data.result.region);
          if (this.item.imageUrl == null) {
            this.item.imageUrl = "";
          }

          this.inputs.regionId = res.data.result.region.id;
          this.inputs.classId = res.data.result.class.id;
          this.inputs.specId = res.data.result.spec.id;
          let fileName = this.item.licenseUrl.split("_");
          fileName = fileName[fileName.length - 1];
          this.file = new File(["1"], fileName);
          this.loading = false;
        });
        // this.inputs = this.item;

        // this.inputs.regionId = this.regions.find((item) => {
        //   return item.name == this.item.regionName;
        // })[0];
        //
      } else {
        this.inputs = {
          fullName: "",
          descrption: "",
          licenseUrl: "",
          longtude: "",
          latitude: "",
          regionId: "",
          closestPoint: "",
          debts: 0,
          officeId: "",
        };
        // this.$refs.addDoctor.reset()
      }
    },
  },
  watch: {
    searchRegion(val) {
      this.getRegions(val);
    },
    dialog() {
      if (this.dialog != true) {
        this.$store.commit("regions/updateStores", {});
        this.$store.commit("regions/toggleEdit", false);
        this.$emit("updated", this.dialog);
        this.$refs.addDoctor.reset();
      } else {
        this.checkIsEdit();
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
